import api from '../iProTubeApi';
import { User } from '../schemas/User.schema';
import { PaginatedResponse } from '../types';
import { objectToParams } from '../utils';
import {
  AddFCMTokenDTO,
  ExistsDTO,
  ExistsResponse,
  GetUsersOptions,
  UpdateUserDTO,
  UpdateUserThumbnailDTO,
  UserIncludesDTO,
} from './types';

export async function patchUser(
  id: string,
  dto: Partial<UpdateUserDTO>,
): Promise<User> {
  return await api.patch(`/users/${id}`, dto).then(({ data }) => data);
}

export async function updateUserThumbnail(
  id: string,
  { file }: Partial<UpdateUserThumbnailDTO>,
): Promise<User> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData: any = new FormData();
  formData.append('thumbnail', file);

  return await api
    .post(`/users/${id}/thumbnail/upload`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
    .then(({ data }) => data);
}

export async function getUsers(
  options?: GetUsersOptions,
): Promise<PaginatedResponse<User>> {
  return await api
    .get(`/users`, {
      params: objectToParams(options),
    })
    .then(({ data }) => data);
}

export async function getUser(
  id: string,
  includes?: UserIncludesDTO,
): Promise<User> {
  return await api
    .get(`/users/${id}`, {
      params: {
        ...includes,
      },
    })
    .then(({ data }) => data);
}

export async function verifyUser(dto: ExistsDTO): Promise<ExistsResponse> {
  return await api
    .get(`/users/verify/exists`, {
      params: {
        ...dto,
      },
    })
    .then(({ data }) => data);
}

export async function addUserFCMToken(
  id: string,
  dto: AddFCMTokenDTO,
): Promise<void> {
  return await api.put(`/users/${id}/fcm-token`, dto).then(({ data }) => data);
}
