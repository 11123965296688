export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 *
 * Based on https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectToParams = (object: any = {}): any => {
  const values = Object.values(object);

  let params = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values.forEach((value: any) => {
    if (isObject(value)) {
      params = { ...params, ...value };
    }
  });
  return params;
};
