import cookieCutter from 'cookie-cutter';
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import firebase from 'firebase/app';

import {
  storeClient,
  storeTeacher,
} from 'services/apiServices/IProTubeApi/authentications';
import { StoreUserDTO } from 'services/apiServices/IProTubeApi/authentications/types';
import { getUser } from 'services/apiServices/IProTubeApi/users';
import { User } from 'services/apiServices/IProTubeApi/schemas/User.schema';
import {
  signInWithFirebaseUsingCredentials,
  signInWithFirebaseUsingPopup,
  firebaseApp,
} from '../../services/firebase';
import { Logger } from 'services/logger';
import { ROLES } from 'config/constants';

const logger = new Logger('AuthProvider');

export type LoginProvider = 'google.com';

export interface AuthState {
  user: User;
  firebaseUser: firebase.User;
  loginProvider?: LoginProvider;
  isTeacher: boolean;
  isClient: boolean;
  isAdmin: boolean;
}

interface SignInCredentials {
  email: string;
  password: string;
}

export interface GetIsAuthorized {
  acceptedRoles?: string[];
}

type RegisterData = StoreUserDTO;
interface IRegisterOptions {
  forceUpdate: boolean;
}
interface AuthContextData {
  state: AuthState;
  isRecognizingState: boolean;
  isUserAuthenticated: () => boolean;
  signInWithEmailAndPassword(credentials: SignInCredentials): Promise<void>;
  signInWithOAuth(): Promise<void>;
  registerClientThenSignInWithEmailAndPassword(
    data: RegisterData,
    options?: IRegisterOptions,
  ): Promise<void>;
  registerTeacherThenSignInWithEmailAndPassword(
    data: RegisterData,
    options?: IRegisterOptions,
  ): Promise<void>;
  signOut(): void;
  hasRole(role: string): boolean;
  reloadUser(): Promise<void>;
  getIsAuthorized(options: GetIsAuthorized): boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [isRecognizingState, setIsRecognizingState] = useState(true);

  const [isTeacher, setIsTeacher] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsTeacher(hasRole(ROLES.TEACHER));
    setIsClient(hasRole(ROLES.CLIENT));
    setIsAdmin(hasRole(ROLES.ADMIN));
  }, [data]);
  const clearAuthState = () => {
    firebase.auth().signOut();
    cookieCutter.set('@menobel-logged-user-firebase-id');
    cookieCutter.set('@menobel-logged-user-id');
    cookieCutter.set('@menobel-logged-user-email');
    cookieCutter.set('@menobel-logged-user-name');
    cookieCutter.set('@menobel-logged-user-lastname');
    cookieCutter.set('@menobel-logged-user-thumbnail');
    cookieCutter.set('@menobel-logged-user-cellphone');
    cookieCutter.set('@menobel-logged-user-stripeId');
    cookieCutter.set('@menobel-logged-user-canReceiveMoney');
    cookieCutter.set('@menobel-logged-user-canBeCharged');
    cookieCutter.set('@menobel-logged-user-roles');
    cookieCutter.set('@menobel-logged-user-category');
    cookieCutter.set('@menobel-logged-user-subCategory');
    cookieCutter.set('@menobel-logged-user-acceptedTerms');
    cookieCutter.set('@menobel-logged-user-first-reference-created-at');
    cookieCutter.set('@menobel-logged-user-stripe-configured-at');

    setData({} as AuthState);
  };

  const signInWithEmailAndPassword = useCallback(
    async ({ email, password }: SignInCredentials) => {
      await signInWithFirebaseUsingCredentials(email, password)
        .then(async firebaseUser => {
          return await updateUserData(firebaseUser);
        })
        .catch(err => {
          clearAuthState();
          throw err;
        });
    },
    [],
  );

  const signInWithOAuth = useCallback(async () => {
    await signInWithFirebaseUsingPopup()
      .catch(err => {
        clearAuthState();
        throw err;
      })
      .then(user => {
        return updateUserData(user);
      });
  }, []);

  const registerClientThenSignInWithEmailAndPassword = useCallback(
    async (data: RegisterData, options?: IRegisterOptions): Promise<void> => {
      const createdUser = await storeClient(data);
      await signInWithEmailAndPassword({
        email: data.email,
        password: data.password,
      });
    },
    [signInWithEmailAndPassword],
  );

  const reloadUser = async (): Promise<void> => {
    const firebaseUser = await firebaseApp.auth().currentUser;
    return updateUserData(firebaseUser);
  };

  const getIsAuthorized = useCallback(
    ({ acceptedRoles = [] }: GetIsAuthorized) => {
      if (!data.user || !data.firebaseUser) return false;

      const isRoleAuthorized =
        acceptedRoles.length === 0 ||
        data.user.roles.some(userRole => acceptedRoles.includes(userRole));

      return isRoleAuthorized;
    },
    [data],
  );

  const updateUserData = useCallback(
    async (fbUser?: firebase.User) => {
      const firebaseUser = fbUser || data.firebaseUser;
      if (firebaseUser) {
        const [providerData] = firebaseUser.providerData;
        logger.debug(`Atualizando usuário ${firebaseUser.email} no estado`);
        await getUser(firebaseUser.uid)
          .then(user => {
            cookieCutter.set(
              '@menobel-logged-user-firebase-id',
              firebaseUser.uid,
            );
            cookieCutter.set('@menobel-logged-user-id', user._id);
            cookieCutter.set('@menobel-logged-user-email', user.email);
            cookieCutter.set('@menobel-logged-user-name', user.name);
            cookieCutter.set('@menobel-logged-user-lastname', user.lastname);
            cookieCutter.set('@menobel-logged-user-thumbnail', user.photoUrl);
            cookieCutter.set('@menobel-logged-user-cellphone', user.cellphone);
            cookieCutter.set('@menobel-logged-user-stripeId', user.stripeId);
            cookieCutter.set(
              '@menobel-logged-user-canReceiveMoney',
              user.canReceiveMoney,
            );
            cookieCutter.set(
              '@menobel-logged-user-canBeCharged',
              user.canBeCharged,
            );
            cookieCutter.set('@menobel-logged-user-roles', user.roles);
            cookieCutter.set('@menobel-logged-user-category', user.category);
            cookieCutter.set(
              '@menobel-logged-user-subCategory',
              user.subCategory,
            );
            cookieCutter.set(
              '@menobel-logged-user-acceptedTerms',
              user.acceptedTerms,
            );
            cookieCutter.set(
              '@menobel-logged-user-first-reference-created-at',
              user.firstReferenceCreatedAt,
            );
            cookieCutter.set(
              '@menobel-logged-user-stripe-configured-at',
              user.stripeConfiguredAt,
            );

            setData(data => ({
              ...data,
              firebaseUser: firebaseUser,
              loginProvider: providerData!.providerId as LoginProvider,
              user,
            }));
          })
          .catch(err => {
            logger.error(
              `Não foi possivel obter usuário ${firebaseUser.email}`,
            );
          })
          .finally(() => {
            setIsRecognizingState(false);
          });
      } else {
        logger.warn(`Não foi possível atualizar usuário indefinido no estado.`);
        setIsRecognizingState(false);
        throw new Error(
          `Não foi possível atualizar usuário indefinido no estado.`,
        );
      }
    },
    [data],
  );

  const registerTeacherThenSignInWithEmailAndPassword = useCallback(
    async (data: RegisterData, options?: IRegisterOptions): Promise<void> => {
      const createdUser = await storeTeacher(data);
      await signInWithEmailAndPassword({
        email: data.email,
        password: data.password,
      });
    },
    [signInWithEmailAndPassword],
  );

  const isUserAuthenticated = useCallback(
    () => !!data.firebaseUser && !!data.user,
    [data],
  );

  const hasRole = useCallback(
    (role: string) => data && data.user && data.user.roles.includes(role),
    [data],
  );

  const refreshAuthState = useCallback(
    async (firebaseUser: firebase.User | null) => {
      logger.debug(`==> refreshAuthState`);
      if (firebaseUser) {
        logger.debug(
          `Atualizando autenticação do usuário ${firebaseUser.email}`,
        );
        await updateUserData(firebaseUser);
      } else {
        clearAuthState();
        setIsRecognizingState(false);
      }
    },
    [],
  );

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged(refreshAuthState);
    firebaseApp.auth().onIdTokenChanged(refreshAuthState);
  }, [refreshAuthState]);

  return (
    <AuthContext.Provider
      value={
        {
          state: {
            ...data,
            isTeacher,
            isClient,
            isAdmin,
          },
          isUserAuthenticated,
          signInWithEmailAndPassword,
          signInWithOAuth,
          registerClientThenSignInWithEmailAndPassword,
          registerTeacherThenSignInWithEmailAndPassword,
          signOut: clearAuthState,
          isRecognizingState,
          hasRole,
          reloadUser,
          getIsAuthorized,
        } as AuthContextData
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
